/** @jsx jsx */
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"
import { jsx, Themed } from "theme-ui"
import SEO from "./seo"
import Layout from "./layout"
import dates from "../util/date"
import ResponsiveEmbed from "./responsive-embed"
import "typeface-literata"

const shortcodes = { Link, ResponsiveEmbed } // Provide common components here

const Header = ({ title, desc }) => (
  <header sx={{ mb: 4 }}>
    <Themed.h1
      sx={{
        // mt: 0,
        mb: 2,
        // fontSize: 4,
        fontWeight: 500,
        // textTransform: "uppercase",
        // letterSpacing: "0.0625em",
        // textAlign: "center",
        maxWidth: "40rem",
      }}
    >
      {title}
    </Themed.h1>
    <Themed.p
      sx={{
        mt: 0,
        // mb: 4,
        fontSize: 1,
        // fontWeight: 500,
        fontStyle: "italic",
        // textTransform: "uppercase",
        // letterSpacing: "0.0625em",
        // textAlign: "center",
        maxWidth: "40rem",
        color: "#aaa",
      }}
    >
      {desc}
    </Themed.p>
  </header>
)

const Footer = ({ date }) => (
  <footer sx={{ mt: 4 }}>
    <p>
      <span sx={{ color: "primary" }}>—</span> <br />
      Preetam D'Souza <br /> {dates.formatLong(date)}
    </p>
  </footer>
)

export default function PageTemplate({ data: { mdx } }) {
  return (
    <Layout>
      <SEO
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.description || mdx.excerpt}
      />
      <article sx={{ maxWidth: "text" }}>
        <Header
          title={mdx.frontmatter.title}
          desc={mdx.frontmatter.description}
        />
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </MDXProvider>
        <Footer date={mdx.frontmatter.date} />
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      timeToRead
      excerpt
      frontmatter {
        title
        description
        date
      }
    }
  }
`
